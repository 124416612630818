
/**
 * Loads an external link by adding it to the <head>
 * @author big schlotz
 * @param {string} href - The href attribute
 * @param {string} id - The id attribute
 * @param {string} rel - The rel attribute
 * @returns {null}
 * @version 1.0.0
 */
export function loadLink({
  href,
  id,
  rel,
}) {
  // Ignore if link is already loaded
  if (document.getElementById(id) === null) {
    // Load the link
    const link = document.createElement("link");
    link.href = href;
    link.id = id;
    link.rel = rel;
    document.head.appendChild(link);
  }
}

/**
 * Loads an external script by adding it to the <head>
 * @author big schlotz
 * @param {string} code - The internal code for the script element
 * @param {string} id - The id attribute
 * @param {Boolean} reload - Reload the script to reload if it already is in the head
 * @param {string} src - The script src value
 * @returns {Promise}
 * @version 1.0.0
 */
export function loadScript({
  code,
  id,
  reload,
  src,
}) {
  return new Promise((resolve, reject) => {
    if (document.getElementById(id) === null || reload) {
      // Remove existing script if possible
      try {
        document.getElementById(id).remove();
      } catch (error) {} // ignore error

      // Load the script
      const script = document.createElement("script");
      script.async = true;
      script.defer = true;
      script.id = id;
      if (code) {
        script.innerHTML = code;
      }
      if (src) {
        script.src = src;
      }
      document.head.appendChild(script);

      // Return
      script.onload = resolve;
      script.onerror = reject;
    } else {
      return resolve();
    }
  });
}
