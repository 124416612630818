// Core
import React from "react";
import styles from "./styles.module.css";

class Cursor extends React.Component {
  componentDidMount() {
    // Only initialise cursor for screens over 900px wide
    if (window && window.innerWidth > 900) {
      // The cursor wrapper
      const cursor_wrapper = document.getElementById("cursor-wrapper");

      // Make cursor element visible
      cursor_wrapper.classList.remove("none");

      // Hide default cursor
      document.body.classList.add("js-cursor");

      // Add listeners
      window.addEventListener("mousedown", this.handleMouseDown);
      window.addEventListener("mousemove", this.handleMouseMove);
      window.addEventListener("mouseup", this.handleMouseUp);
    }
  }

  handleMouseDown() {
    // The cursor element
    const cursor = document.getElementById("cursor");

    // Add the mousedown class
    cursor.classList.add(styles["cursor--mousedown"]);
  }

  handleMouseMove(event) {
    // The cursor elements
    const cursor = document.getElementById("cursor");
    const cursor_wrapper = document.getElementById("cursor-wrapper");

    // Set the cursor's co-ordinates
    const x = event.clientX - cursor_wrapper.getBoundingClientRect().left;
    const y = event.clientY - cursor_wrapper.getBoundingClientRect().top;
    cursor.style.left = `${x}px`;
    cursor.style.top = `${y}px`;

    // Determine if the cursor is hovering a clickable element
    const hovered_elements = document.querySelectorAll(":hover");
    if (Array.from(hovered_elements).find(element => ["A", "BUTTON", "INPUT"].includes(element.tagName))) {
      cursor.classList.add(styles["cursor--hover"]);
    } else {
      cursor.classList.remove(styles["cursor--hover"]);
    }
  }

  handleMouseUp() {
    // The cursor element
    const cursor = document.getElementById("cursor");

    // Remove the mousedown class
    cursor.classList.remove(styles["cursor--mousedown"]);
  }

  render() {
    return (
      <div
        className={`${styles["cursor-wrapper"]} none`}
        id="cursor-wrapper"
      >
        <div
          aria-hidden="true"
          className={styles.cursor}
          focusable="false"
          id="cursor"
        ></div>
      </div>
    );
  }
}

export default Cursor;
