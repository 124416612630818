// Core
import PropTypes from "prop-types";
import React from "react";
import styles from "./styles.module.css";

class Accordion extends React.Component {
  toggleAccordion(event) {
    let accordion;

    // Don't toggle accordion when a link is clicked within it
    if (event) {
      if (event.target.tagName === "A") {
        return;
      }

      accordion = event.currentTarget;
    } else {
      accordion = this.accordionRef.current;
    }

    // The accordion elements
    const accordion_body = accordion.querySelector("[data-accordion-body]");

    // The current accordion state
    const is_open = accordion.getAttribute("aria-expanded") === "true";

    // Toggle aria attributes
    if (is_open) {
      accordion.setAttribute("aria-expanded", "false");
      accordion.setAttribute("aria-label", "Open Accordion");
    } else {
      accordion.setAttribute("aria-expanded", "true");
      accordion.setAttribute("aria-label", "Close Accordion");
    }

    // Toggle accordion body
    if (is_open) {
      accordion_body.setAttribute("aria-hidden", "true");
      accordion_body.style.height = 0;
      accordion_body.style.maxHeight = 0;
    } else {
      accordion_body.removeAttribute("aria-hidden");
      accordion_body.style.height = accordion_body.scrollHeight + "px";
      accordion_body.style.maxHeight = accordion_body.scrollHeight + "px";
    }

    // Toggle classes of accordion internal elements
    const internal_elements = accordion.querySelectorAll("[data-accordion-open-classname]");

    internal_elements.forEach(element => {
      const open_classname = element.getAttribute("data-accordion-open-classname");

      if (is_open) {
        element.classList.remove(open_classname);
      } else {
        element.classList.add(open_classname);
      }
    });
  }

  constructor(props) {
    super(props);
    this.accordionRef = React.createRef();
  }

  componentDidMount() {
    if (this.props.expanded) {
      this.toggleAccordion();
    }
  }

  render() {
    return (
      <button
        {...this.props.props}
        aria-expanded="false"
        aria-label="Open Accordion"
        className={[
          styles.accordion,
          this.props.borderBottom ? "border-bottom" : "",
          this.props.borderTop ? "border-top" : "",
          this.props.className || "",
        ].join(" ")}
        onClick={this.toggleAccordion}
        ref={this.accordionRef}
        type="button"
      >
        {this.props.children}
      </button>
    );
  }
}

Accordion.propTypes = {
  borderBottom: PropTypes.bool,
  borderTop: PropTypes.bool,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  props: PropTypes.object,
};

export default Accordion;
