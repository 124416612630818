// Core
import PropTypes from "prop-types";
import React from "react";
// Components
import {
  Footer,
  Navbar,
  Seo,
} from "../components/";
// Styles
import "./styles/reset.css";
import "./styles/root.css";
import "./styles/typography.css";
import "./styles/buttons.css";
import "./styles/containers.css";
import "./styles/display.css";
import "./styles/flex.css";
import "./styles/global.css";
import "./styles/position.css";
import "./styles/size.css";
import "./styles/spacing.css";
import "./styles/slick.css";

// Utilities
import {
  loadLink,
  loadScript,
} from "../utilities/";

class Layout extends React.Component {
  constructor(props) {
    super(props);
    this.loadScripts = this.loadScripts.bind(this);
  }

  componentDidMount() {
    if (typeof window !== "undefined") {
      // Load scripts after page interaction
      window.addEventListener("keydown", this.loadScripts);
      window.addEventListener("mousemove", this.loadScripts);
      window.addEventListener("scroll", this.loadScripts);
      window.addEventListener("touchstart", this.loadScripts);
    }
  }

  loadScripts() {
    // Remove event listeners
    window.removeEventListener("keydown", this.loadScripts);
    window.removeEventListener("mousemove", this.loadScripts);
    window.removeEventListener("scroll", this.loadScripts);
    window.removeEventListener("touchstart", this.loadScripts);

    // AOS
    loadLink({
      href: "https://unpkg.com/aos@2.3.1/dist/aos.css",
      id: "aos-stylesheet",
      rel: "stylesheet",
    });
    loadScript({
      id: "aos-js",
      src: "https://unpkg.com/aos@2.3.1/dist/aos.js",
    })
    .then(() => {
      window.AOS.init({
        duration: 1000,
        offset: 400,
      });
    })
    .catch(() => {
      document.getElementById("aos-stylesheet").remove();
    });
  }

  render() {
    const { children } = this.props;
    return (
      <React.Fragment>
        <Seo {...this.props.seo} />
        <Navbar />
        <main role="main">{children}</main>
        <Footer />
      </React.Fragment>
    );
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  seo: PropTypes.object,
};

export default Layout;
