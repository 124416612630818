import React from "react";
import styles from "./styles.module.css";

class Footer extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <footer className={styles.footer}>
        <div className="container">
          <p>Copyright echocast © {new Date().getFullYear()}. All rights reserved.</p>
        </div>
      </footer>
    );
  }
}

export default Footer;
