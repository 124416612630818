// Core
import { GatsbyImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";
import React from "react";
// Third Party Components
import { BLOCKS, INLINES, MARKS } from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

class RichText extends React.Component {
  render() {
    const options = {
      renderMark: {
        [MARKS.BOLD]: text => <strong>{text}</strong>,
        [MARKS.ITALIC]: text => <em>{text}</em>,
        [MARKS.UNDERLINE]: text => <u>{text}</u>,
      },
      renderNode: {
        [BLOCKS.EMBEDDED_ASSET]: node => {
          const { gatsbyImageData, title } = this.props.references.find(x => x.contentful_id === node.data.target.sys.id);
          return (
            <GatsbyImage
              alt={title}
              image={gatsbyImageData}
            />
          );
        },
        [INLINES.HYPERLINK]: (node, children) => {
          return (
            <a
              href={node.data.uri}
              rel="noreferrer noopener"
              target="_blank"
            >
              {children}
            </a>
          );
        },
      },
      renderText: text => {
        return text.split("\n").reduce((children, textSegment, index) => {
          return [...children, index > 0 && <br key={index} />, textSegment];
        }, []);
      },
    };

    return documentToReactComponents(this.props.json, options);
  }
}

RichText.propTypes = {
  json: PropTypes.object.isRequired,
  references: PropTypes.array,
};

export default RichText;
