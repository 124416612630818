// Core
import {
  graphql,
  StaticQuery,
} from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import styles from "./styles.module.css";
// Components
import {
  Link,
} from "../";

class Navbar extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (window) {
      window.addEventListener("scroll", this.handleScroll);
      window.addEventListener("keydown", this.handleKeydown);
    }
  }

  componentWillUnmount() {
    if (window) {
      window.removeEventListener("scroll", this.handleScroll);
      window.removeEventListener("keydown", this.handleKeydown);
    }
  }

  render() {
    const logo = this.props.contentfulSettingsGeneralSiteSettings.logo.gatsbyImageData;
    const site_name = this.props.contentfulSettingsGeneralSiteSettings.siteName;

    return (
      <header className={styles.header}>
        <div className="container">
          <nav
            aria-label="Main Navigation"
            className={styles.navbar}
            id="navbar"
            itemScope="itemscope"
            itemType="https://schema.org/SiteNavigationElement"
            role="navigation"
          >
            <div className={styles.navbar__inner}>
              <Link
                className={`${styles.logo}`}
                to="/"
              >
                <GatsbyImage
                  alt={site_name}
                  image={logo}
                />
              </Link>
            </div>
          </nav>
        </div>
      </header>
    );
  }
}

export default function NavbarWithQuery(props) {
  return (
    <StaticQuery
      query={graphql`
        query {
          contentfulSettingsGeneralSiteSettings {
            siteName
            logo {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
            }
          }
        }
      `}
      render={data => (
        <Navbar {...data} {...props} />
      )}
    />
  );
}
